<template>
    <properties-component v-bind="$props" @close="$emit('close')">
        <slot name="before"></slot>

        <b-form-group label-cols="4" :label="'Display|Afficher'|tr" v-if="component.useBinding">
            <select-cms-item-field :item-type="block.itemType" v-model="component.glyphField" field-type="glyph"/>
        </b-form-group>

        <b-form-group v-if="!(component.useBinding && component.glyphField)" :label="'Glyph|Icône'|tr">
            <input-glyph v-model="component.glyph"/>
        </b-form-group>

        <b-form-group>
            <btn-responsive-prop v-model="component" field="color" no-label/>
            <input-color v-model="component.mediaProxy.color" use-css-var button block allow-inherit label="Color|Couleur"/>
        </b-form-group>

        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Size|Taille</span>
                <btn-responsive-prop v-model="component" field="size"/>
            </template>
            <e-input type="number" v-model.number="component.mediaProxy.size" :min="12" :max="300" placeholder="Auto" state="disabled"/>
            <span class="unit">px</span>
        </b-form-group>

        <b-form-group label-cols="4">
            <template #label>
                <span v-tr>Spacing|Espace</span>
                <btn-responsive-prop v-model="component" field="spacing"/>
            </template>
            <b-select v-model="component.mediaProxy.spacing">
                <option value="0" v-tr>None|Sans</option>
                <option v-for="n in 100" :key="`width-${n}`" :value="`${n}px`">{{n}}px</option>
            </b-select>
        </b-form-group>

<!--        <sidebar-group title="Glyph Style|Style de l'icône" :expanded.sync="styleExpanded" sub-group>-->
<!--        </sidebar-group>-->

        <b-form-group v-if="!component.useBinding" :label="'Link|Lien'|tr">
            <input-link v-model="component.link" allow-empty/>
        </b-form-group>

        <slot></slot>
    </properties-component>
</template>

<script>
import BtnResponsiveProp from "@/components/editor/properties/components/btn-responsive-prop.vue";
import PropertiesComponent from "@/components/editor/properties/components/properties-component.vue";
import SidebarGroup from "@/components/editor/properties/components/sidebar-group.vue";
import InputColor from "@/components/editor/properties/inputs/input-color.vue";
import InputGlyph from "@/components/editor/properties/inputs/input-glyph.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";
import EInput from "../../../../../vue-components/components/e-input.vue";

// properties-component-glyph #hot-reload-debug
export default {
    name: `properties-component-glyph`,
    components: { InputGlyph, BtnResponsiveProp, SidebarGroup, InputColor, InputLink, SelectCmsItemField, EInput, PropertiesComponent},
    extends: PropertiesComponent,
    data() {
        return {
            styleExpanded: true
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
