<template>
    <properties-block  v-bind="$props" v-on="$listeners">
        <properties-component-link title="Link|Lien" :value="block.link" v-bind="componentProps"/>
        <properties-component-image title="Image" :value="block.image" v-bind="componentProps"/>
        <properties-component-glyph title="Glyph|Icône" :value="block.glyph" v-bind="componentProps"/>
        <properties-component-text title="Title|Titre" :value="block.title" v-bind="componentProps"/>
        <properties-component-text title="Subtitle|Sous-titre" :value="block.subtitle" v-bind="componentProps"/>
        <properties-component-text title="Description" :value="block.description" v-bind="componentProps"/>
        <properties-component-button title="Main Action|Action principale" :value="block.mainButton" v-bind="componentProps"/>
        <properties-component-button title="Alt Action|Action secondaire" :value="block.altButton" v-bind="componentProps"/>
    </properties-block>
</template>

<script>
import PropertiesComponentButton from "@/components/editor/properties/components/properties-component-button.vue";
import PropertiesComponentGlyph from "@/components/editor/properties/components/properties-component-glyph.vue";
import PropertiesComponentImage from "@/components/editor/properties/components/properties-component-image.vue";
import PropertiesComponentLink from "@/components/editor/properties/components/properties-component-link.vue";
import PropertiesComponentText from "@/components/editor/properties/components/properties-component-text.vue";
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";
import InputLink from "@/components/editor/properties/inputs/input-link.vue";
import LinkPicker from "@/components/editor/properties/inputs/link-picker.vue";
import SelectCmsItemField from "@/components/editor/properties/inputs/select-cms-item-field.vue";

// properties-block-card #hot-reload-debug
export default {
    name: `properties-block-card`,
    components: {
        PropertiesComponentGlyph,
        PropertiesComponentLink, SelectCmsItemField, InputLink, LinkPicker, PropertiesComponentImage, PropertiesComponentButton, PropertiesComponentText, PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
